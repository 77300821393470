import React, { useEffect, useState ,useRef} from "react";
import SubHeader from "../resusableComponent/SubHeader";
import { useMsal } from "@azure/msal-react";
import "./pendingOrderReport.css";
import BottomInfoBar from "../resusableComponent/BottomInfoBar";
import Multiselect from "multiselect-react-dropdown";
import Dropdown from 'react-bootstrap/Dropdown';
import MUIDataTable from "mui-datatables";
import { IconButton, Tooltip } from "@mui/material";
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import { CircularProgress,Box } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { REACT_APP_JAVA_BASE_URL, REACT_APP_SUBSCRIPTION_KEY } from "../settings"


const back = "back";
const subHeading = "Pending Order Report";

function PendingOrderReport(props) {
  const { accounts } = useMsal();
  const userEmail = accounts[0].idTokenClaims.email;

  const onClickBack = (path) => {
    props.history.push(path);
  };
  // State to manage sidebar visibility
  const [columns, setColumns] = useState([]); // Dynamic columns from API
  const [data, setData] = useState([]); // Data fetched from API
  const [filterData, setFilterData] = useState(null); // Data filtered by filters
  const [loading,setLoading]=useState(true)
  const isFirstRender = useRef(true);

  const clearFilters=()=>{
    setLoading(true);
    setFilterData([])
    setFilters({
      selectedClients:[],
      selectedSubClients:[],
      selectedCustomerID:[],
      selectedMaterialNo:[],
      selectedWarehouse:[],
      selectedSalesOrderNo:[],
      selectedSalesRejection:[]
    })
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }

  const [startDate,setStartDate]=useState('')
  const [endDate,setEndDate]=useState('')
  // Utility functions to calculate dynamic date ranges
  const getCurrentDate = () => {
    const today = new Date();
    return today.toISOString().split("T")[0]; // Format: YYYY-MM-DD
  };

  const getMinDate = (daysBack) => {
    const date = new Date();
    date.setDate(date.getDate() - daysBack);
    return date.toISOString().split("T")[0];
  };

  const getMaxDate = (daysAhead) => {
    const date = new Date();
    date.setDate(date.getDate() + daysAhead);
    return date.toISOString().split("T")[0];
  };

  const handleInformation = () => {
    //Route to information page
    const path="/connect_client/navigator/lite_standard/pending_order_report_information"
    props.history.push(path);
  };
  // State to manage sidebar visibility
  const [isSidebarVisible, setIsSidebarVisible] = useState(true);
  /**Function to toggle sidebar visibility */
  const toggleSidebar = () => {
    setIsSidebarVisible(!isSidebarVisible);
  };
  // Fetch data from backend API
  const fetchData = async () => {
    try {
      const url = new URL(`${REACT_APP_JAVA_BASE_URL}/api/parquet/dataFromAzure`);
      // const url = new URL("http://localhost:8080/api/parquet/dataFromAzure");
      const headers= {
            "Ocp-Apim-Subscription-Key": REACT_APP_SUBSCRIPTION_KEY,
            "Access-Control-Allow-Origin": "*",
          }
      const params = { email_address: userEmail };

      if (startDate) params.oldDate = startDate;
      if (endDate) params.newDate = endDate;
      // Append query parameters to the URL
      url.search = new URLSearchParams(params).toString();

      // Make the fetch request with the updated URL
      const response = await fetch(url,{
        method: 'GET', // or 'POST' if needed
        headers: headers,
      });

      // const response = await fetch("http://localhost:8080/api/parquet/dataFromAzure");
      const result = await response.json();

      if (result && result.length > 0) {
        const columnKeys = Object.keys(result[0]); // Extract column names from keys
        const columnData = columnKeys.map((key, index) => ({ id: index + 1, name: key }));

        setColumns(columnData);
        setData(result);
        setFilterData(result)
        setClients([...new Set(result.map(item=>item.ClientDescription).filter(Boolean))])
        setsubClients([...new Set(result.map(item=>item.SubClientDesc).filter(Boolean))])
        setCustomerID([...new Set(result.map(item=>item.CustomerDescription).filter(Boolean))])
        setMaterialID([...new Set(result.map(item=>item.MaterialDescription).filter(Boolean))])
        // setWarehouse([...new Set(result.map(item=>item.warehouse))])
        setSalesOrderNo([...new Set(result.map(item=>item.SalesOrderNo).filter(Boolean))])
        setSalesRejection([...new Set(result.map(item=>item.SalesRejectionDesc).filter(Boolean))])
        console.log("Data fetch success")
        setLoading(false)
      }
      if (result && result.length === 0) {
        setColumns([]);
        setData([]);
        setFilterData([])
        setClients([])
        setsubClients([])
        setCustomerID([])
        setMaterialID([])
        // setWarehouse([])
        setSalesOrderNo([])
        setSalesRejection([])
        console.log("No Data Found")
        setLoading(false)
      }
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };
  useEffect(() => {
    setStartDate(getMinDate(14))
    setEndDate(getCurrentDate())
    // fetchData();
    if (window.location.hostname === 'connectclient.dksh.com' || window.location.hostname === 'connectclient.vnbizbox.com') {
      window.appInsights.setAuthenticatedUserContext(userEmail, userEmail);
      window.appInsights.trackPageView({ name: 'Pending Order Report' });
    }
  }, []);

  const options = {
    // filterType: "checkbox",
    filter:false,
    selectableRows:false,

    search:false,
    print:false,
    download:true,
    textLabels:{
      body:{
        noMatch:'Sorry, no matching records found',
      }
    },
    customToolbar:(searchText,handleSearch)=>{
      return(
        <Tooltip>
          <b>Select Columns :</b>
        </Tooltip>
      )
    }
  };
  const [clients, setClients] = React.useState([]);
  const [subClients, setsubClients] = React.useState([]);
  const [customerID, setCustomerID] = React.useState([]);
  const [materialID, setMaterialID] = React.useState([]);
  const [warehouse, setWarehouse] = React.useState([]);
  const [salesOrderNo, setSalesOrderNo] = React.useState([]);
  const [salesRejection, setSalesRejection] = React.useState([]);

  const [filters,setFilters]=useState({
    selectedClients:[],
    selectedSubClients:[],
    selectedCustomerID:[],
    selectedMaterialNo:[],
    selectedWarehouse:[],
    selectedSalesOrderNo:[],
    selectedSalesRejection:[]
  })

  const changeFilters=(event,value,name)=>{
    setLoading(true)
    // const {
    //       target: { value },
    //     } = event;
    // const {
    //       target: { name },
    //     } = event;
    setFilters((prev)=>({
      ...prev,
      [name]:typeof value === 'string' ? value.split(',') : value,
    }))
  }
  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    setFilterData(data.filter(item=>
      ((filters.selectedClients).length===0 || (filters.selectedClients).includes(item.ClientDescription))&&
      ((filters.selectedSubClients).length===0 || (filters.selectedSubClients).includes(item.SubClientDesc))&&
      ((filters.selectedCustomerID).length===0 || (filters.selectedCustomerID).includes(item.CustomerDescription))&&
      ((filters.selectedMaterialNo).length===0 || (filters.selectedMaterialNo).includes(item.MaterialDescription))&&
      // ((filters.selectedWarehouse).length===0 || (filters.selectedWarehouse).includes(item.Warehouse))&&
      ((filters.selectedSalesOrderNo).length===0 || (filters.selectedSalesOrderNo).includes(item.SalesOrderNo))&&
      ((filters.selectedSalesRejection).length===0 || (filters.selectedSalesRejection).includes(item.SalesRejectionDesc))
    ));
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, [filters]);

  // function sleep(duration) {
  //   return new Promise((resolve) => {
  //     setTimeout(() => {
  //       resolve();
  //     }, duration);
  //   }); 
  // }
  const selectDate=(event)=>{
    const {
            target: { value },
          } = event;
    const {
          target: { name },
        } = event;
    console.log(name, value)
    if(name==='startDate'){
      setStartDate(value)
    }else if(name==='endDate'){
      setEndDate(value)
    }    
  }
  useEffect(() => {
    if(startDate && endDate !==''){
      if(new Date(startDate)<=new Date(endDate)){
        setLoading(true)
        fetchData()
      }
    }
  }, [startDate,endDate]);

  const handleFilterOpen = (name) => {      
      (async () => {
        // setLoading(true);
        console.log("handle open filter",name)
        // await sleep(1000);
        // setsubClients([...new Set(filterData.map(item=>item.SubClientDesc).filter(Boolean))])
        // setLoading(false);
        switch(name){
          case 'selectedClients':
            setsubClients([...new Set(filterData.map(item=>item.SubClientDesc).filter(Boolean))])
            setCustomerID([...new Set(filterData.map(item=>item.CustomerDescription).filter(Boolean))])
            setMaterialID([...new Set(filterData.map(item=>item.MaterialDescription).filter(Boolean))])
            // setWarehouse([...new Set(filterData.map(item=>item.warehouse))])
            setSalesOrderNo([...new Set(filterData.map(item=>item.SalesOrderNo).filter(Boolean))])
            setSalesRejection([...new Set(filterData.map(item=>item.SalesRejectionDesc).filter(Boolean))])
            break;
          case 'selectedSubClients':
            setsubClients([...new Set(
              data.filter(item=>(filters.selectedClients).length===0 || filters.selectedClients.includes(item.ClientDescription))
              .map(item=>item.SubClientDesc)
              .filter(Boolean)
            )])
            break;
          case 'selectedMaterialNo':
            setMaterialID([...new Set(
              data.filter(item=>(filters.selectedClients).length===0 || filters.selectedClients.includes(item.ClientDescription))
              .filter(item=>(filters.selectedSubClients).length===0 || filters.selectedSubClients.includes(item.SubClientDesc))
              .map(item=>item.MaterialDescription)
              .filter(Boolean)
            )])
            break;
          case 'selectedCustomerID':
            setCustomerID([...new Set(
              data.filter(item=>(filters.selectedClients).length===0 || filters.selectedClients.includes(item.ClientDescription))
              .filter(item=>(filters.selectedSubClients).length===0 || filters.selectedSubClients.includes(item.SubClientDesc))
              .filter(item=>(filters.selectedMaterialNo).length===0 || filters.selectedMaterialNo.includes(item.MaterialDescription))
              .map(item=>item.CustomerDescription)
              .filter(Boolean)
            )])
            break;
          case 'selectedWarehouse':
            // setWarehouse([...new Set(
            //   data.filter(item=>(filters.selectedClients).length===0 || filters.selectedClients.includes(item.ClientDescription))
            //   .filter(item=>(filters.selectedSubClients).length===0 || filters.selectedSubClients.includes(item.SubClientDesc))
            //   .filter(item=>(filters.selectedMaterialNo).length===0 || filters.selectedMaterialNo.includes(item.MaterialDescription))
            //   .filter(item=>(filters.selectedCustomerID).length===0 || filters.selectedCustomerID.includes(item.CustomerDescription))
            //   .map(item=>item.Warehouse)
            //   .filter(Boolean)
            // )])
            break;
          case 'selectedSalesRejection':
            setSalesRejection([...new Set(
              data.filter(item=>(filters.selectedClients).length===0 || filters.selectedClients.includes(item.ClientDescription))
              .filter(item=>(filters.selectedSubClients).length===0 || filters.selectedSubClients.includes(item.SubClientDesc))
              .filter(item=>(filters.selectedMaterialNo).length===0 || filters.selectedMaterialNo.includes(item.MaterialDescription))
              .filter(item=>(filters.selectedCustomerID).length===0 || filters.selectedCustomerID.includes(item.CustomerDescription))
              .filter(item=>(filters.selectedWarehouse).length===0 || filters.selectedWarehouse.includes(item.Warehouse))
              .map(item=>item.SalesRejectionDesc)
              .filter(Boolean)
            )])
            break;
          case 'selectedSalesOrderNo':
            setSalesOrderNo([...new Set(
              data.filter(item=>(filters.selectedClients).length===0 || filters.selectedClients.includes(item.ClientDescription))
              .filter(item=>(filters.selectedSubClients).length===0 || filters.selectedSubClients.includes(item.SubClientDesc))
              .filter(item=>(filters.selectedMaterialNo).length===0 || filters.selectedMaterialNo.includes(item.MaterialDescription))
              .filter(item=>(filters.selectedCustomerID).length===0 || filters.selectedCustomerID.includes(item.CustomerDescription))
              .filter(item=>(filters.selectedWarehouse).length===0 || filters.selectedWarehouse.includes(item.Warehouse))
              .filter(item=>(filters.selectedSalesRejection).length===0 || filters.selectedSalesRejection.includes(item.SalesRejectionDesc))
              .map(item=>item.SalesOrderNo)
              .filter(Boolean)
            )])
            break;
          default:
            return
        }
      })();
  };
  return (
    <div>
      {loading &&
        <div className="loadingBox" >
          <CircularProgress style={{color:'rgb(171, 30, 52)'}}/>
        </div>
      }
      <div>
        <SubHeader
          back={back}
          subHeading={subHeading}
          isImage="false"
          onClickBack={onClickBack}
          path="/connect_client/navigator/lite_standard"
        />
      </div>
      {/**UI Logic */}
      <div className="dashboard-container">

        {/* Sidebar */}
        <aside className={`sidebar ${isSidebarVisible ? 'visible' : 'hidden'}`}>
          <div className="d-flex mb-2">
            <button
              className="clear-filters-btn p-2" onClick={clearFilters}
            >
              Clear Filters
            </button>
          </div>
    
          <FormControl>
            <div className="filter-group">
              <label>Sales Order Start Date</label>
              <input
                type="date"
                className="date-picker"
                name="startDate"
                onChange={selectDate}
                // defaultValue={getMinDate(14)}
                value={startDate}
                min={getMinDate(90)} /* Calculate 90 days before today */
                max={getCurrentDate()} /* Set max to today's date */
              />
            </div>
            <div className="filter-group">
              <label>Sales Order End Date</label>
                <input
                  type="date"
                  className="date-picker"
                  name="endDate"
                  onChange={selectDate}
                  // defaultValue={getCurrentDate()}
                  value={endDate}
                  min={getMinDate(90)} /* Calculate 90 days before today */
                  max={getCurrentDate()} /* Set max to today's date */
                />
            </div>
          <div className="filter-group">
              <label>Client</label>
              <Autocomplete
                multiple
                size="small"
                onOpen={()=>handleFilterOpen('selectedClients')}
                options={clients}
                getOptionLabel={(option) => option}
                onChange={(event, newValue) => changeFilters(event, newValue, 'selectedClients')} // Pass updated values
                disableClearable
                value={filters.selectedClients}
                renderInput={(params) => <TextField {...params}  placeholder="Select"/>}
                renderOption={(props, option, { selected }) => (
                  <MenuItem {...props} key={option} value={option}>
                    <Checkbox checked={selected} />
                    <ListItemText primary={option} />
                  </MenuItem>
                )}
                MenuProps={{
                  PaperProps: {
                    style: {
                      padding: '4px 0', // Adjust padding (this reduces the padding inside the dropdown list)
                    }
                  }
                }}
                >
              </Autocomplete>
            </div>
            <div className="filter-group">
              <label>Sub Client</label>
                <Autocomplete
                  multiple
                  size="small"
                  onOpen={()=>handleFilterOpen('selectedSubClients')}
                  options={subClients}
                  getOptionLabel={(option) => option}
                  onChange={(event, newValue) => changeFilters(event, newValue, 'selectedSubClients')} // Pass updated values
                  disableClearable
                  value={filters.selectedSubClients}
                  renderInput={(params) => <TextField {...params} placeholder="Select"/>}
                  renderOption={(props, option, { selected }) => (
                    <MenuItem {...props} key={option} value={option}>
                      <Checkbox checked={selected} />
                      <ListItemText primary={option} />
                    </MenuItem>
                  )}
                >
              </Autocomplete>
            </div>
            <div className="filter-group">
              <label>Material</label>
                <Autocomplete
                  multiple
                  size="small"
                  onOpen={()=>handleFilterOpen('selectedMaterialNo')}
                  options={materialID}
                  getOptionLabel={(option) => option}
                  onChange={(event, newValue) => changeFilters(event, newValue, 'selectedMaterialNo')} // Pass updated values
                  disableClearable
                  value={filters.selectedMaterialNo}
                  renderInput={(params) => <TextField {...params} placeholder="Select"/>}
                  renderOption={(props, option, { selected }) => (
                    <MenuItem {...props} key={option} value={option}>
                      <Checkbox checked={selected} />
                      <ListItemText primary={option} />
                    </MenuItem>
                  )}
                >
              </Autocomplete>
            </div>
            <div className="filter-group">
              <label>Customer</label>
                <Autocomplete
                  multiple
                  size="small"
                  onOpen={()=>handleFilterOpen('selectedCustomerID')}
                  options={customerID}
                  getOptionLabel={(option) => option}
                  onChange={(event, newValue) => changeFilters(event, newValue, 'selectedCustomerID')} // Pass updated values
                  disableClearable
                  value={filters.selectedCustomerID}
                  renderInput={(params) => <TextField {...params} placeholder="Select"/>}
                  renderOption={(props, option, { selected }) => (
                    <MenuItem {...props} key={option} value={option}>
                      <Checkbox checked={selected} />
                      <ListItemText primary={option} />
                    </MenuItem>
                  )}
                >
              </Autocomplete>
            </div>
            <div className="filter-group">
              <label>Warehouse</label>
                <Select
                  displayEmpty
                  multiple
                  value={filters.selectedWarehouse}
                  // onChange={handleChange}
                  name='selectedWarehouse'
                  onChange={changeFilters}
                  renderValue={(selected) => selected.length === 0 ? "Select" : selected.join(", ")}
                >
                  {warehouse.map((name) => (
                    <MenuItem key={name} value={name}>
                      <Checkbox checked={filters.selectedWarehouse.includes(name)} />
                      <ListItemText primary={name} />
                    </MenuItem>
                  ))}
                </Select>
                {/* <Autocomplete
                  multiple
                  size="small"
                  onOpen={()=>handleFilterOpen('selectedWarehouse')}
                  // options={warehouse}
                  getOptionLabel={(option) => option}
                  onChange={(event, newValue) => changeFilters(event, newValue, 'selectedWarehouse')} // Pass updated values
                  disableClearable
                  value={filters.selectedWarehouse}
                  renderInput={(params) => <TextField {...params} />}
                  renderOption={(props, option, { selected }) => (
                    <MenuItem {...props} key={option} value={option}>
                      <Checkbox checked={selected} />
                      <ListItemText primary={option} />
                    </MenuItem>
                  )}
                ></Autocomplete> */}
            </div>
            <div className="filter-group">
              <label>Sales Rejection</label>
                <Autocomplete
                  multiple
                  size="small"
                  onOpen={()=>handleFilterOpen('selectedSalesRejection')}
                  options={salesRejection}
                  getOptionLabel={(option) => option}
                  onChange={(event, newValue) => changeFilters(event, newValue, 'selectedSalesRejection')} // Pass updated values
                  disableClearable
                  value={filters.selectedSalesRejection}
                  renderInput={(params) => <TextField {...params} placeholder="Select"/>}
                  renderOption={(props, option, { selected }) => (
                    <MenuItem {...props} key={option} value={option}>
                      <Checkbox checked={selected} />
                      <ListItemText primary={option} />
                    </MenuItem>
                  )}
                ></Autocomplete>
            </div>
            <div className="filter-group">
              <label>Sales Order No</label>
                <Autocomplete
                  multiple
                  size="small"
                  onOpen={()=>handleFilterOpen('selectedSalesOrderNo')}
                  options={salesOrderNo}
                  getOptionLabel={(option) => option}
                  onChange={(event, newValue) => changeFilters(event, newValue, 'selectedSalesOrderNo')} // Pass updated values
                  disableClearable
                  value={filters.selectedSalesOrderNo}
                  renderInput={(params) => <TextField {...params} placeholder="Select"/>}
                  renderOption={(props, option, { selected }) => (
                    <MenuItem {...props} key={option} value={option}>
                      <Checkbox checked={selected} />
                      <ListItemText primary={option} />
                    </MenuItem>
                  )}
                ></Autocomplete>
            </div>
          </FormControl>
        </aside>

        {/* Toggle Button for Sidebar */}
        <div className={`sidebar-toggle-btn  ${isSidebarVisible ? 'left-13' : 'left-1'}`} onClick={toggleSidebar}>
            {isSidebarVisible ?
            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24"><path d="M12 2a10 10 0 1 0 10 10A10.011 10.011 0 0 0 12 2zm0 18a8 8 0 1 1 8-8 8.009 8.009 0 0 1-8 8z"/><path d="M13.293 7.293 8.586 12l4.707 4.707 1.414-1.414L11.414 12l3.293-3.293-1.414-1.414z"/></svg>
            :<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24"><path d="M12 2a10 10 0 1 0 10 10A10.011 10.011 0 0 0 12 2zm0 18a8 8 0 1 1 8-8 8.009 8.009 0 0 1-8 8z"/><path d="M9.293 8.707 12.586 12l-3.293 3.293 1.414 1.414L15.414 12l-4.707-4.707-1.414 1.414z"/></svg>
            }
        </div>

        {/* Main Content */}
        <div className="content">
          {/* Top buttons div */}
          <div className="top-btns">
            {/* <button className="load-btn" onClick={handleClearFilters}>
              Load Report
            </button> */}
            <button className="load-btn" onClick={handleInformation}>
              Information
            </button>
          </div>
          {filterData &&
            <MUIDataTable
              className="data-display"
              title={""}
              data={filterData}
              columns={columns}
              options={options}
            />
          }
        </div>
      </div>
      <BottomInfoBar />
    </div>
  );
}

export default PendingOrderReport;
