import React, { useState, props, useEffect } from "react";
import { useMsal } from "@azure/msal-react";
import "./pendingOrderReportInformation.css";
import SubHeader from "../resusableComponent/SubHeader";
import BottomInfoBar from "../resusableComponent/BottomInfoBar";
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import { REACT_APP_JAVA_BASE_URL, REACT_APP_SUBSCRIPTION_KEY } from "../settings"
import { CircularProgress, Box, List } from "@mui/material";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { blue } from '@mui/material/colors';
import { DataGrid, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';

function PendingOrderReportInformation(props) {
  // Handle button click
  const [activeButton, setActiveButton] = useState(1);
  const handleClick = (buttonIndex) => {
    setActiveButton(buttonIndex);
  };
  const [loading, setLoading] = useState(false)
  const [columns, setColumns] = useState([]);
  const [columnsTable2, setColumnsTable2] = useState([]);
  const [dataTable2, setDataTable2] = useState([]);
  const [data, setData] = useState([]);
  let rowsWithTempIds = []
  const fetchData = async () => {
    setLoading(true)
    try {
      const url = new URL(`${REACT_APP_JAVA_BASE_URL}/api/parquet/readCSV`);
      // const url = new URL("http://localhost:8080/api/parquet/readCSV");
      const headers = {
        "Ocp-Apim-Subscription-Key": REACT_APP_SUBSCRIPTION_KEY,
        "Access-Control-Allow-Origin": "*",
      }
      const response = await fetch(url, {
        method: 'GET',
        headers: headers,
      });
      const result = await response.json();
      {
        if (result && result.SheetData.length > 0) {
          const columnKeys = Object.keys(result.SheetData[0]); // Extract column names from keys
          // const reorderedColumnKeys = [...columnKeys].reverse();
          const columnData = columnKeys.map((key, index) => ({ id: index + 1, name: key }));
          console.log(columnData)
          setColumns(columnData);
          console.log(result.SheetData)
          rowsWithTempIds=(result.SheetData).map((row, index) => ({ id: `temp-id-${index}`, ...(result.SheetData) }));
          setData(result.SheetData);
        }
      }

      setLoading(false)
      console.log(response)
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };
  const onClickBack = (path) => {
    props.history.push(path);
  };
  useEffect(() => {
    fetchData()

  }, [])
  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport />
        <GridToolbarColumnsButton/>
      </GridToolbarContainer>
    );
  }
  return (
    <>
      <div>
        <SubHeader
          back={'back'}
          subHeading={'Pending Order Report Information'}
          isImage="false"
          onClickBack={onClickBack}
          path="/connect_client/navigator/lite_standard/pending_order_report"
        />
      </div>
      <div>
        <div>
          {loading &&
            <div className="loadingBox" >
              <CircularProgress style={{ color: '#ab1e34' }} />
            </div>
          }
        </div>
        <ButtonGroup className="custombutton" aria-label="Basic button group">
          <Button onClick={() => handleClick(1)}
            sx={{
              borderRadius: 0,
              borderWidth: 2,
              backgroundColor: activeButton === 1 ? '#ab1e34' : 'white',
              color: activeButton === 1 ? 'white' : 'black',
              '&:hover': { backgroundColor: activeButton === 1 ? '#ab1e34' : 'white' },
              transition: 'background-color 0.3s ease ,color 0.3s ease',
            }}>Glossary</Button>
          <Button onClick={() => handleClick(2)}
            sx={{
              fontWeight: 'bold',
              borderRadius: 0,
              borderWidth: 2,
              backgroundColor: activeButton === 2 ? '#ab1e34' : 'white',
              color: activeButton === 2 ? 'white' : 'black',
              '&:hover': { backgroundColor: activeButton === 2 ? '#ab1e34' : 'white' },
              transition: 'background-color 0.3s ease ,color 0.3s ease',
            }}>Version Log</Button>
          <Button onClick={() => handleClick(3)}
            sx={{
              fontWeight: 'bold',
              borderRadius: 0,
              borderWidth: 2,
              backgroundColor: activeButton === 3 ? '#ab1e34' : 'white',
              color: activeButton === 3 ? 'white' : 'black',
              '&:hover': { backgroundColor: activeButton === 3 ? '#ab1e34' : 'white' },
              transition: 'background-color 0.3s ease ,color 0.3s ease',
            }}>User Manual</Button>
        </ButtonGroup>
        {activeButton === 1 && (
          <TableContainer className="container1">
            <Table >
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell key={column.id} className="header1">
                      {column.name}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody >
                {data.map((row, rowIndex) => (
                  <tr className="demo" key={rowIndex}>
                    {columns.map((column) => (
                      <td className="demo" key={column.id}>{row[column.name]}</td> // Access the data based on the column id
                    ))}
                  </tr>
                ))}

              </TableBody>
            </Table>
          </TableContainer>
        )}

        {/* Table 2 */}
        {activeButton === 2 && (
          
          // <h1>
          //   Version log
          // </h1>
          <div style={{display:"block",height:'30vw'}}>
            <DataGrid
              slots={{ toolbar: CustomToolbar }}
              rows={rowsWithTempIds}
              columns={columns}
            />
          </div>
        )}

        {/* Table 3 */}
        {activeButton === 3 && (
          <h1>
            User Manual
          </h1>
        )}
      </div>

      <BottomInfoBar />
    </>
  );
}


export default PendingOrderReportInformation;
